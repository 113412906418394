<template>
  <div>
    <v-chip
      v-for="perm in permissionList"
      :key="perm"
      dark
      :outlined="!permissions[perm]"
      :color="permColor(perm)"
      @click="togglePermission(perm)"
      class="ml-1 mb-1 text-capitalize"
    >
      {{ label(perm) }}
      <!-- <v-icon small>
        {{ permissions[perm] ? 'mdi-close' : 'mdi-plus' }}
      </v-icon> -->
    </v-chip>
  </div>
</template>

<script>
import _ from 'lodash';
import { PERMISSIONS } from '../../utils/organization';
export default {
  props: {
    value: { type: Object, required: true }
  },
  data() {
    return {
      labels: {
        editPhotoGps: 'Edit Photo GPS'
      },
      permissionList: Object.keys(PERMISSIONS),
      permissions: {
        create: false,
        read: false,
        update: false,
        delete: false,
        addMember: false,
        editPhotoGps: false
      }
    };
  },
  watch: {
    value: {
      handler: 'setPermissions',
      deep: true,
      immediate: true
    }
  },
  created() {
    this.$emit('input', this.permissions);
  },
  methods: {
    label(value) {
      if (this.label[value]) {
        return this.label[value];
      }
      return _.startCase(value);
    },
    togglePermission(value) {
      this.permissions[value] = !this.permissions[value];
      this.$emit('input', this.permissions);
    },
    setPermissions(value) {
      if (value && typeof value === 'object') {
        this.permissions = { ...this.permissions, ...value };
      }
    },
    permColor(perm) {
      return PERMISSIONS[perm].color;
    }
  }
};
</script>

<style>
</style>
